
.home-container {
    
    .stats-wrapper{
        // width: 100%;
        display: flex;
        flex-direction: row;
        height: 200px;
        background-color: #c62828;
        justify-content: center;
        align-items: center;
        padding: 100px;
        gap: 20px;
        margin-top: 40px;
        margin-bottom: 50px;

        .stats-item {
            height: 300px;
            width: 300px;
            // border: 1px solid white;
            display: flex;
            flex-direction: column;
            color: white;
            // justify-content: center;
            align-items: center;

            .stat-icon {
                width: 100px;
            }
            .stat-icon.doctor {
                margin-top: 15px;
                width: 60px;
            }
            .stat-icon.experience {
                margin-top: 15px;
                width: 70px;
            }
            .stat-figure {
                font-size: 80px;
            }
            .stat-figure.doctor {
                font-size: 80px;
                margin-top: 25px;
            }
            .stat-figure.experience {
                font-size: 80px;
                margin-top: 15px;
            }
            .stat-description {
                font-size: 20px;
            }
        }
    }

    .services-container {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
        // border: 1px solid red;
        .services-header {
            margin-left: 50px;
            font-size: 20px;
            font-weight: 300;
            border-bottom: 20px;
            // border-color: red;
            // border: 1px solid red;

            hr {
                margin: 10px 0px 10px 0px;
                width: 40px;
                border: 1px solid #c62828;
            }
        }


        .services-wrapper {
            display: flex;
            flex-direction: row;
            padding: 20px 50px;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 20px;
            //justify-content: flex-start;
            //gap: 20px;
        
            .services-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border: 1px solid #c5c5c5;
                // width: 50%;
                width: 160px;
                height: 150px;
                // border-radius: 2em;
                padding: 10px 10px 10px 10px;
                overflow: hidden;

                img {
                    width: 50px;
                    height: 50px;
                }

                .description {
                    text-align: center;
                }

                p{
                    font-size: 15px;
                }
            }
        }

    }

    .whyUs-container {
        margin-top: 80px;
        // margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        // background-color: #e0f7fa;
        background-color: #f5f5f5;
        height: 500px;

        .whyUs-wrapper {
            max-width: 1300px;
            // border: 1px solid red;
            margin: 50px auto 0px auto;

            .whyUs-header {
                margin-left: 50px;
                font-size: 20px;
                font-weight: 300;
                border-bottom: 20px;
                // border-color: red;
                // border: 1px solid red;
    
                hr {
                    margin: 10px 0px 10px 50px;
                    width: 40px;
                    border: 1px solid #c62828;
                }
            }

            .whyUs-items {
                display: flex;
                flex-direction: row;
                padding: 50px;
                gap: 50px;

                .whyUs-item {
                    height: 250px;
                    width: 250px;
                    // border: 1px solid red;
                    display:flex;
                    flex-direction: column;
                    align-items: center;
                    // border-radius: 1em;
                    gap: 20px;
                    padding: 20px 10px 0px 10px;
                    background-color: white;
                    box-shadow: 0px 10px 23px rgb(0 0 0 / 12%);
                    
                    .whyUs-item-header {
                        display:flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 20px;

                        .whyUs-icon {
                            width: 50px;
                            margin: 0px auto 0px auto;
                            // border: 1px solid red;
                        }

                    }

                    .whyUs-description {
                        text-align: center;
                        font-size: 13px;
                    }
                }

                .whyUs-item.active {
                    background-color: #c62828;
                    color: white;
                }
            }
            
        }

    }

    .consult-container {
        width: 100%;
        height: 200px;
        background-image: url("../../assets/images/bg5.jpg");
        background-size: cover;
        background-position: center;
        position: relative;

        .consult-darkOverlay {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: #00b8d4;
            opacity: 0.7;
        }

        .consult-wrapper {
            width: 100%;
            position: absolute;
            display: flex;
            flex-direction: row;

            .consult-left {
                width: 70%;
                height: 100%;
                // border: 1px solid brown;
                color: white;
                padding: 40px 0px 0px 50px;

                p:nth-child(1) {
                    font-size: 40px;
                }
            }
            .consult-right {
                width: 30%;
                height: 200px;
                // border: 1px solid brown;
                display: flex;
                align-items: center;

                .link {

                    text-decoration: none;

                    .consult-button {
                        width: 300px;
                        background-color: white;
                        color: #00b8d4;
                        padding: 10px;
                        text-align: center;
                        cursor: pointer;
                    }

                }
            }
        }
    }

    .peopleSay-container {
        width: 100%;
        height: 450px;
        background-image: url("../../assets/images/peoplesay.jpg");
        background-size: cover;
        background-position: center;
        position: relative;
        display: flex;

        .peopleSay-darkOverlay {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: black;
            opacity: 0.5;
        }

            .peopleSay-card {
                width: 400px;
                height: 250px;
                padding: 20px 50px;
                background-color: white;
                border-radius: 1em;
                position: relative;
                // margin-top: 30px;
                margin: 45px auto;

                .peopleSay-mark {
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background-color: #00b8d4;
                    font-size: 20px;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: -60px;
                    margin-top: -30px;

                    img {
                        width: 20px;
                    }
                }
                .peopleSay-wrapper{
                    display: flex;
                    flex-direction: column;
                    // border: 1px solid red;
                    width: 450px;
                    padding: 5px;
                    margin-left: -30px;
                    // margin-top: 30px;

                    .peopleSay-comment {
                        width: 450px;
                        padding: 5px;
                        font-size: 14px;
                    }
                    .peopleSay-user {
                        font-style: italic;
                        width: 400px;
                        padding: 5px;
                    }
                    .peopleSay-rating {
                        // border: 1px solid red;
                        // width: auto;
                        max-width: fit-content;
                        height: 20px;
                        // padding: 5px;
                        img {
                            width: 15px;
                            height: 15px;
                        }
                    }
                }

            }
    }

    .reachOut-container {
        width: 100%;
        height: 550px;
        display: flex;
        flex-direction: column;

        .reachOut-wrapper {
            max-width: 1300px;
            width: 1000px;
            height: 450px;
            // border: 1px solid red;
            border-radius: 2em;
            margin: auto auto;
            display: flex;
            flex-direction: row;
            box-shadow: 0px 10px 23px rgb(0 0 0 / 12%);

            .reachOut-left {
                width: 60%;
                // border: 1px solid black;
                display: flex;
                flex-direction: column;
                gap: 30px;
                padding: 20px 30px;

                .headerText p:nth-child(1){
                    font-size: 30px;
                    // color: #c62828;
                    // color: #2196f3;
                    opacity: 0.4;
                }

                .headerText p:nth-child(2){
                    font-weight: 300;
                    // color: #ccccff;
                }

                .location {
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                    font-size: 15px;
                }

                .phone {
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                    font-size: 15px;
                }

                .email {
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                    font-size: 15px;
                }

                .book-appointment {
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                    font-size: 15px;
                    background-color: #c62828;
                    color: white;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;
                    border-radius: 3px;
                }

                .reachout-icon {
                    width: 25px;
                }
            }

            .reachOut-right {
                width: 40%;
                // border: 1px solid black;
                display: flex;
                flex-direction: column;
                padding: 30px;

                .msg-form {
                    display: flex;
                    flex-direction: column;

                    .msg-form-header {
                        font-weight: 400;
                        color: #00b8d4;
                        // opacity: 0.6;
                    }

                    form {
                        display: flex;
                        flex-direction: column;
                        // border: 1px solid red;
                        gap: 30px;
                        margin-top: 20px;

                        .formInput {
                            width: 100%;
                            outline: none;
                            border: none;
                            border-bottom: 1px solid #ccccff;
                            padding: 10px 0px;
                        }
                        .formTextArea {
                            width: 100%;
                            outline: none;
                            border: none;
                            border-bottom: 1px solid #ccccff;
                            padding: 10px 0px;
                        }
                        section {
                            display: flex;
                            flex-direction: column;
                            gap: 30px;
                        }
                        .send-button {
                            // background-color: #00b8d4;
                            background-color: #c62828;
                            outline: none;
                            border: none;
                            height: 50px;
                            border-radius: 2em;
                            color: white;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }

}

@media only screen and (min-width: 320px) and (max-width: 400px){ //Mobile

    .home-container {

        .services-container {
            margin-top: 20px;
            .services-header {
                margin-left: 10px;
                font-size: 15px;
                font-weight: 300;
            }
            .services-wrapper {
                padding: 20px 10px;
                flex-wrap: wrap;
                gap: 15px;
                justify-content: space-around;
                //gap: 20px;
            
                .services-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    // border: 1px solid #c5c5c5;
                    width: 130px;
                    height: 100px;
                    // border-radius: 2em;
                    padding: 10px 10px 10px 10px;
                    overflow: hidden;
    
                    img {
                        width: 50px;
                        height: 50px;
                    }
    
                    .description {
                        text-align: center;
                    }
    
                    p{
                        font-size: 10px;
                    }
                }
            }
        }

        .stats-wrapper{
            // width: 100%;
            display: flex;
            flex-direction: column;
            height: auto;
            background-color: #c62828;
            justify-content: center;
            align-items: center;
            padding: 50px;
            gap: 20px;
            margin-top: 0px;
            margin-bottom: 50px;

            .stats-item {
                height: 130px;
                width: 130px;
                // border: 1px solid white;
                display: flex;
                flex-direction: column;
                color: white;
                // justify-content: center;
                align-items: center;

                .stat-icon {
                    width: 70px;
                }
                .stat-icon.doctor {
                    margin-top: 15px;
                    width: 40px;
                }
                .stat-icon.experience {
                    margin-top: 15px;
                    width: 50px;
                }
                .stat-figure {
                    font-size: 30px;
                    margin-top: -15px;
                }
                .stat-figure.doctor {
                    font-size:30px;
                    margin-top: 0px;
                }
                .stat-figure.experience {
                    font-size: 30px;
                    margin-top: -7px;
                }
                .stat-description {
                    font-size: 10px;
                }
            }
        }      
        
        .whyUs-container {
            margin-top: 80px;
            // margin-top: 20px;
            display: flex;
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;
            // background-color: #e0f7fa;
            background-color: #f5f5f5;
            height: auto;
    
            .whyUs-wrapper {
                max-width: 1300px;
                // border: 1px solid red;
                margin: 50px auto 0px auto;
                display: flex;
                flex-direction: column;
                align-items: center;
    
                .whyUs-header {
                    margin-left: 0px;
                    font-size: 15px;
                    font-weight: 300;
                    border-bottom: 20px;
                    width: 100%;
                    // border-color: red;
                    // border: 1px solid red;
        
                    hr {
                        margin: 10px 0px 10px 0px;
                        width: 40px;
                        border: 1px solid #c62828;
                    }
                }
    
                .whyUs-items {
                    display: flex;
                    flex-direction: column;
                    padding: 30px 0px 30px 0px;
                    gap: 50px;
    
                    .whyUs-item {
                        height: 250px;
                        width: 250px;
                        // border: 1px solid red;
                        display:flex;
                        flex-direction: column;
                        align-items: center;
                        // border-radius: 1em;
                        gap: 20px;
                        padding: 20px 10px 0px 10px;
                        background-color: white;
                        box-shadow: 0px 10px 23px rgb(0 0 0 / 12%);
                        
                        .whyUs-item-header {
                            display:flex;
                            flex-direction: column;
                            align-items: center;
                            gap: 20px;
    
                            .whyUs-icon {
                                width: 50px;
                                margin: 0px auto 0px auto;
                                // border: 1px solid red;
                            }
    
                        }
    
                        .whyUs-description {
                            text-align: center;
                            font-size: 13px;
                        }
                    }
    
                    .whyUs-item.active {
                        background-color: #c62828;
                        color: white;
                    }
                }
                
            }
    
        }

        .consult-container {
            width: 100%;
            height: 400px;
            background-image: url("../../assets/images/bg5.jpg");
            background-size: cover;
            background-position: center;
            position: relative;
    
            .consult-darkOverlay {
                position: absolute;
                height: 100%;
                width: 100%;
                background-color: #00b8d4;
                opacity: 0.7;
            }
    
            .consult-wrapper {
                width: 100%;
                position: absolute;
                display: flex;
                flex-direction: column;
    
                .consult-left {
                    width: 100%;
                    color: white;
                    padding: 10px 0px 0px 0px;
                    text-align: center;

                    p:nth-child(1) {
                        font-size: 35px;
                        text-align: center;
                        font-weight: 500;
                        padding: 15px;
                    }
                    p:nth-child(2) {
                        font-size: 17px;
                        text-align: center;
                        font-weight: 300;
                        padding: 15px;
                    }
                }

                .consult-right {
                    width: 100%;
                    height: 100px;
                    display: flex;
                    // align-items: center;
                    justify-content: center;
                    // border: 1px solid;

                    .consult-button {
                        width: 250px;
                        background-color: white;
                        color: #00b8d4;
                        padding: 10px;
                        text-align: center;
                        cursor: pointer;
                    }
                }
            }
        }

        .reachOut-container {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            background-color: none;
    
            .reachOut-wrapper {
                max-width: 1300px;
                width: 100%;
                height: auto;
                // border: 1px solid red;
                border-radius: 2em;
                margin: auto auto;
                display: flex;
                flex-direction: column;
                box-shadow: 0px 10px 23px rgb(0 0 0 / 12%);
    
                .reachOut-left {
                    width: 90%;
                    // border: 1px solid black;
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    padding: 20px 20px;
    
                    .headerText p:nth-child(1){
                        font-size: 20px;
                        // color: #c62828;
                        // color: #2196f3;
                        opacity: 0.4;
                        text-align: center;
                    }
    
                    .headerText p:nth-child(2){
                        font-weight: 300;
                        // color: #ccccff;
                        text-align: center;
                    }
    
                    .location {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                        font-size: 15px;
                    }
    
                    .phone {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                        font-size: 15px;
                    }
    
                    .email {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                        font-size: 15px;
                    }
    
                    .book-appointment {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                        font-size: 15px;
                        background-color: #c62828;
                        color: white;
                        align-items: center;
                        justify-content: center;
                        padding: 10px;
                        border-radius: 3px;
                    }
    
                    .reachout-icon {
                        width: 25px;
                    }
                }
    
                .reachOut-right {
                    width: 90%;
                    // border: 1px solid black;
                    display: flex;
                    flex-direction: column;
                    padding: 30px 20px;
    
                    .msg-form {
                        display: flex;
                        flex-direction: column;
    
                        .msg-form-header {
                            font-weight: 400;
                            color: #00b8d4;
                            text-align: center;
                            // opacity: 0.6;
                        }
    
                        form {
                            display: flex;
                            flex-direction: column;
                            // border: 1px solid red;
                            gap: 50px;
                            margin-top: 40px;
    
                            .formInput {
                                width: 100%;
                                outline: none;
                                border: none;
                                border-bottom: 1px solid #ccccff;
                                padding: 10px 0px;
                            }
                            .formTextArea {
                                width: 100%;
                                outline: none;
                                border: none;
                                border-bottom: 1px solid #ccccff;
                                padding: 10px 0px;
                            }
                            section {
                                display: flex;
                                flex-direction: column;
                                gap: 30px;
                            }
                            .send-button {
                                // background-color: #00b8d4;
                                background-color: #c62828;
                                outline: none;
                                border: none;
                                height: 50px;
                                border-radius: 2em;
                                color: white;
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }

        .peopleSay-container {
            width: 100%;
            height: 450px;
            background-image: url("../../assets/images/peoplesay.jpg");
            background-size: cover;
            background-position: center;
            position: relative;
    
            .peopleSay-darkOverlay {
                position: absolute;
                height: 100%;
                width: 100%;
                background-color: black;
                opacity: 0.5;
            }
    
            .peopleSay-card {
                width: 200px;
                height: 300px;
                // width: 100%;
                // padding: 10px 30px; //commented...
                background-color: white;
                border-radius: 1em;
                position: relative;
                // margin-top: 30px;
                margin: 29px auto;

                .peopleSay-mark {
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background-color: #00b8d4;
                    // background-color: #1de9b6;
                    font-size: 20px;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: -59px;
                    margin-top: -38px;

                    img {
                        width: 17px;
                    }
                }
                .peopleSay-wrapper{
                    display: flex;
                    flex-direction: column;
                    // border: 1px solid green;
                    width: 250px;
                    // width: 100%;
                    padding: 5px; //commented...
                    margin-left: -30px;

                    .peopleSay-comment {
                        // width: 400px;
                        width: 100%;
                        padding: 5px;
                        font-size: 12px;
                        // border: 1px solid red;
                        margin-left: -7px;
                    }
                    .peopleSay-user {
                        font-style: italic;
                        width: 400px;
                        padding: 5px;
                    }
                    .peopleSay-rating {
                        // border: 1px solid red;
                        // width: auto;
                        max-width: fit-content;
                        height: 20px;
                        // padding: 5px;
                        img {
                            width: 15px;
                            height: 15px;
                        }
                    }
                }

            }
        }
    
    }
}

@media only screen and (min-width: 401px) and (max-width: 450px){ //Mobile

    .home-container {

        .services-container {
            margin-top: 20px;
            .services-header {
                margin-left: 10px;
                font-size: 15px;
                font-weight: 300;
            }
            .services-wrapper {
                padding: 20px 10px;
                flex-wrap: wrap;
                gap: 15px;
                justify-content: space-around;
                //gap: 20px;
            
                .services-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #c5c5c5;
                    // width: 50%;
                    width: 130px;
                    height: 100px;
                    // border-radius: 2em;
                    padding: 10px 10px 10px 10px;
                    overflow: hidden;
    
                    img {
                        width: 50px;
                        height: 50px;
                    }
    
                    .description {
                        text-align: center;
                    }
    
                    p{
                        font-size: 10px;
                    }
                }
            }
        }

        .stats-wrapper{
            // width: 100%;
            display: flex;
            flex-direction: column;
            height: auto;
            background-color: #c62828;
            justify-content: center;
            align-items: center;
            padding: 50px;
            gap: 20px;
            margin-top: 0px;
            margin-bottom: 50px;

            .stats-item {
                height: 130px;
                width: 130px;
                // border: 1px solid white;
                display: flex;
                flex-direction: column;
                color: white;
                // justify-content: center;
                align-items: center;

                .stat-icon {
                    width: 70px;
                }
                .stat-icon.doctor {
                    margin-top: 15px;
                    width: 40px;
                }
                .stat-icon.experience {
                    margin-top: 15px;
                    width: 50px;
                }
                .stat-figure {
                    font-size: 30px;
                    margin-top: -15px;
                }
                .stat-figure.doctor {
                    font-size:30px;
                    margin-top: 0px;
                }
                .stat-figure.experience {
                    font-size: 30px;
                    margin-top: -7px;
                }
                .stat-description {
                    font-size: 10px;
                }
            }
        }

        .whyUs-container {
            margin-top: 80px;
            // margin-top: 20px;
            display: flex;
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;
            // background-color: #e0f7fa;
            background-color: #f5f5f5;
            height: auto;
    
            .whyUs-wrapper {
                max-width: 1300px;
                // border: 1px solid red;
                margin: 50px auto 0px auto;
                display: flex;
                flex-direction: column;
                align-items: center;
    
                .whyUs-header {
                    margin-left: 0px;
                    font-size: 15px;
                    font-weight: 300;
                    border-bottom: 20px;
                    width: 100%;
                    // border-color: red;
                    // border: 1px solid red;
        
                    hr {
                        margin: 10px 0px 10px 0px;
                        width: 40px;
                        border: 1px solid #c62828;
                    }
                }
    
                .whyUs-items {
                    display: flex;
                    flex-direction: column;
                    padding: 30px 0px 30px 0px;
                    gap: 50px;
    
                    .whyUs-item {
                        height: 250px;
                        width: 250px;
                        // border: 1px solid red;
                        display:flex;
                        flex-direction: column;
                        align-items: center;
                        // border-radius: 1em;
                        gap: 20px;
                        padding: 20px 10px 0px 10px;
                        background-color: white;
                        box-shadow: 0px 10px 23px rgb(0 0 0 / 12%);
                        
                        .whyUs-item-header {
                            display:flex;
                            flex-direction: column;
                            align-items: center;
                            gap: 20px;
    
                            .whyUs-icon {
                                width: 50px;
                                margin: 0px auto 0px auto;
                                // border: 1px solid red;
                            }
    
                        }
    
                        .whyUs-description {
                            text-align: center;
                            font-size: 13px;
                        }
                    }
    
                    .whyUs-item.active {
                        background-color: #c62828;
                        color: white;
                    }
                }
                
            }
    
        }

        .consult-container {
            width: 100%;
            height: 400px;
            background-image: url("../../assets/images/bg5.jpg");
            background-size: cover;
            background-position: center;
            position: relative;
    
            .consult-darkOverlay {
                position: absolute;
                height: 100%;
                width: 100%;
                background-color: #00b8d4;
                opacity: 0.7;
            }
    
            .consult-wrapper {
                width: 100%;
                position: absolute;
                display: flex;
                flex-direction: column;
    
                .consult-left {
                    width: 100%;
                    color: white;
                    padding: 10px 0px 0px 0px;
                    text-align: center;

                    p:nth-child(1) {
                        font-size: 35px;
                        text-align: center;
                        font-weight: 500;
                        padding: 15px;
                    }
                    p:nth-child(2) {
                        font-size: 17px;
                        text-align: center;
                        font-weight: 300;
                        padding: 15px;
                    }
                }

                .consult-right {
                    width: 100%;
                    height: 100px;
                    display: flex;
                    // align-items: center;
                    justify-content: center;
                    // border: 1px solid;

                    .consult-button {
                        width: 250px;
                        background-color: white;
                        color: #00b8d4;
                        padding: 10px;
                        text-align: center;
                        cursor: pointer;
                    }
                }
            }
        }

        .reachOut-container {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            background-color: none;
    
            .reachOut-wrapper {
                max-width: 1300px;
                width: 100%;
                height: auto;
                // border: 1px solid red;
                border-radius: 2em;
                margin: auto auto;
                display: flex;
                flex-direction: column;
                box-shadow: 0px 10px 23px rgb(0 0 0 / 12%);
    
                .reachOut-left {
                    width: 90%;
                    // border: 1px solid black;
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    padding: 20px 20px;
    
                    .headerText p:nth-child(1){
                        font-size: 20px;
                        // color: #c62828;
                        // color: #2196f3;
                        opacity: 0.4;
                        text-align: center;
                    }
    
                    .headerText p:nth-child(2){
                        font-weight: 300;
                        // color: #ccccff;
                        text-align: center;
                    }
    
                    .location {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                        font-size: 15px;
                    }
    
                    .phone {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                        font-size: 15px;
                    }
    
                    .email {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                        font-size: 15px;
                    }
    
                    .book-appointment {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                        font-size: 15px;
                        background-color: #c62828;
                        color: white;
                        align-items: center;
                        justify-content: center;
                        padding: 10px;
                        border-radius: 3px;
                    }
    
                    .reachout-icon {
                        width: 25px;
                    }
                }
    
                .reachOut-right {
                    width: 90%;
                    // border: 1px solid black;
                    display: flex;
                    flex-direction: column;
                    padding: 30px 20px;
    
                    .msg-form {
                        display: flex;
                        flex-direction: column;
    
                        .msg-form-header {
                            font-weight: 400;
                            color: #00b8d4;
                            text-align: center;
                            // opacity: 0.6;
                        }
    
                        form {
                            display: flex;
                            flex-direction: column;
                            // border: 1px solid red;
                            gap: 50px;
                            margin-top: 40px;
    
                            .formInput {
                                width: 100%;
                                outline: none;
                                border: none;
                                border-bottom: 1px solid #ccccff;
                                padding: 10px 0px;
                            }
                            .formTextArea {
                                width: 100%;
                                outline: none;
                                border: none;
                                border-bottom: 1px solid #ccccff;
                                padding: 10px 0px;
                            }
                            section {
                                display: flex;
                                flex-direction: column;
                                gap: 30px;
                            }
                            .send-button {
                                // background-color: #00b8d4;
                                background-color: #c62828;
                                outline: none;
                                border: none;
                                height: 50px;
                                border-radius: 2em;
                                color: white;
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }

        .peopleSay-container {
            width: 100%;
            height: 450px;
            background-image: url("../../assets/images/peoplesay.jpg");
            background-size: cover;
            background-position: center;
            position: relative;
    
            .peopleSay-darkOverlay {
                position: absolute;
                height: 100%;
                width: 100%;
                background-color: black;
                opacity: 0.5;
            }
    
            .peopleSay-card {
                width: 200px;
                height: 300px;
                // width: 100%;
                // padding: 10px 30px; //commented...
                background-color: white;
                border-radius: 1em;
                position: relative;
                // margin-top: 30px;
                margin: 29px auto;

                .peopleSay-mark {
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background-color: #00b8d4;
                    // background-color: #1de9b6;
                    font-size: 20px;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: -59px;
                    margin-top: -38px;

                    img {
                        width: 17px;
                    }
                }
                .peopleSay-wrapper{
                    display: flex;
                    flex-direction: column;
                    // border: 1px solid green;
                    width: 250px;
                    // width: 100%;
                    padding: 5px; //commented...
                    margin-left: -30px;

                    .peopleSay-comment {
                        // width: 400px;
                        width: 100%;
                        padding: 5px;
                        font-size: 12px;
                        // border: 1px solid red;
                        margin-left: -7px;
                    }
                    .peopleSay-user {
                        font-style: italic;
                        width: 400px;
                        padding: 5px;
                    }
                    .peopleSay-rating {
                        // border: 1px solid red;
                        // width: auto;
                        max-width: fit-content;
                        height: 20px;
                        // padding: 5px;
                        img {
                            width: 15px;
                            height: 15px;
                        }
                    }
                }

            }
        }
    
    }
}

@media only screen and (min-width: 801px) and (max-width: 960px){ //Tablet

    .home-container {

        .services-container {
            margin-top: 20px;
            .services-header {
                margin-left: 10px;
                font-size: 15px;
                font-weight: 300;
            }
            .services-wrapper {
                padding: 20px 10px;
                flex-wrap: wrap;
                gap: 15px;
                justify-content: flex-start;
                //gap: 20px;
            
                .services-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #c5c5c5;
                    // width: 50%;
                    width: 130px;
                    height: 100px;
                    // border-radius: 2em;
                    padding: 10px 10px 10px 10px;
                    overflow: hidden;
    
                    img {
                        width: 50px;
                        height: 50px;
                    }
    
                    .description {
                        text-align: center;
                    }
    
                    p{
                        font-size: 10px;
                    }
                }
            }
        }
    }

}