.navBar {

        position: relative;
        z-index: 13;

        .navBar-header {
            // border: 1px solid blue;
            padding: 10px 30px 10px 10px;
            height: 40px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 13px;
            z-index: 1;
            margin-top: 10px;

            .link {
                text-decoration: none;
                // color: white;
                color: #9e9e9e;
            }
            .link:hover {
                text-decoration: none;
                color: #c62828;
            }
            .link.active {
                text-decoration: none;
                // color: white;
                color: #c62828;
            }

            .navBar-header-logo {
                width: 20%;

                .nav-logo {
                    width: 140px;
                    // border: 1px solid red;
                    margin: -7px 0px 0px 0px;
                    position: absolute;
                    cursor: pointer;
                }
            }

            .navBar-header-right {
                width: 45%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                // border: 1px solid ;

                .book {
                    padding: 7px 15px;
                    // border: 1px solid;
                    // border-radius: 25em;
                    // background-color: #2196f3;
                    background-color: #c62828;
                    // background-color: #e53935;
                    color: white;
                }
            }

            .navbar-hamburger {
                display: flex;
                flex-direction: column;
                width: 30px;
                cursor: pointer;
                // display: none;
                // z-index: 7;
                visibility: hidden;

                .hamOne {
                    background-color: #c62828;
                    height: 2px;
                    margin-bottom: 7px;
                    border-radius: 50px;
                    width: 60%;
                }
                
                .hamTwo {
                    background-color: #c62828;
                    height: 2px;
                    margin-bottom: 7px;
                    border-radius: 50px;
                }
                
                .hamThree {
                    background-color: #c62828;
                    height: 2px;
                    border-radius: 50px;
                    width: 70%;
                    /* margin-left: 10px; */
                }
            }
        }
        .navbar-sidebar {
            // height: 100vh;
            // width: 99%;
            // background-color: white;
            // border: 1px solid blue;
            // position: fixed;
            // top: 70px;
            // left: 0.4%;
            // z-index: 13;
            display: none;

            .navbar-overlay{
                height: 100vh;
                width: 99%;
                position: fixed;
            }
            .navbar-overlay.open{
                display: inline-block;
                background-color: black;
                opacity: 0.3;
            }
        }
}

@media only screen and (min-width: 320px) and (max-width: 400px){ //Mobile

    .navBar {

        .navBar-header{
            .navBar-header-right {
                display: none;
            }
            .navbar-hamburger{
                visibility: visible;
            }
        }
        .navbar-sidebar {
            height: 100vh;
            width: 100%;
            background-color: white;
            // border: 1px solid blue;
            position: fixed;
            top: -870px;
            left: 0px;
            transition: 500ms;
            z-index: 13;

            display: flex;
            flex-direction: column;
            gap: 50px;
            align-items: center;
            padding-top: 30px;

            .link {
                text-decoration: none;
                // color: white;
                color: #9e9e9e;
            }
            .link.active {
                text-decoration: none;
                // color: white;
                color: #c62828;
            }
            .book {
                padding: 7px 15px;
                background-color: #c62828;
                color: white;
            }
        }
        .navbar-sidebar.open {
            top: 70px;
            transition: 500ms;
        }
    }
}

@media only screen and (min-width: 401px) and (max-width: 450px){ //Mobile
    .navBar {
        .navBar-header{
            .navBar-header-right {
                display: none;
            }
            .navbar-hamburger{
                visibility: visible;
            }
        }
        .navbar-sidebar {
            height: 100vh;
            width: 99%;
            background-color: white;
            // border: 1px solid blue;
            // position: absolute;
            position: fixed;
            // top: -290%;
            top: -1870px;
            left: 0.4%;
            z-index: 13;
            transition: 500ms;
            display: flex;
            flex-direction: column;
            gap: 50px;
            align-items: center;
            padding-top: 30px;

            .link {
                text-decoration: none;
                // color: white;
                color: #9e9e9e;
            }
            .link.active {
                text-decoration: none;
                // color: white;
                color: #c62828;
            }
            .book {
                padding: 7px 15px;
                background-color: #c62828;
                color: white;
            }
        }
        .navbar-sidebar.open {
            top: 70px;
            transition: 500ms;
        }
    }
}

@media only screen and (min-width: 451px) and (max-width: 800px){ //Mini Tablet
    .navBar {
        .navBar-header {
            .navBar-header-right {
                display: none;
            }
            .navbar-hamburger{
                visibility: visible;
            }
        }
        .navbar-sidebar {
            height: 100vh;
            width: 99%;
            background-color: white;
            // border: 1px solid blue;
            // position: absolute;
            position: fixed;
            // top: -290%;
            top: -1870px;
            left: 0.4%;
            transition: 500ms;
            z-index: 13;
            display: flex;
            flex-direction: column;
            gap: 50px;
            align-items: center;
            padding-top: 30px;

            .link {
                text-decoration: none;
                // color: white;
                color: #9e9e9e;
            }
            .link.active {
                text-decoration: none;
                // color: white;
                color: #c62828;
            }
            .book {
                padding: 7px 15px;
                background-color: #c62828;
                color: white;
            }
        }
        .navbar-sidebar.open {
            top: 70px;
            transition: 500ms;
        }
    }
}

@media only screen and (min-width: 801px) and (max-width: 960px){ //Tablet
    .navBar {
        .navBar-header {
            .navBar-header-right {
                display: none;
            }
            .navbar-hamburger{
                visibility: visible;
            }
        }
        .navbar-sidebar {
            height: 100vh;
            width: 99%;
            background-color: white;
            // border: 1px solid blue;
            // position: absolute;
            position: fixed;
            // top: -290%;
            top: -1870px;
            left: 0.4%;
            z-index: 13;
            transition: 500ms;
            display: flex;
            flex-direction: column;
            gap: 50px;
            align-items: center;
            padding-top: 30px;

            .link {
                text-decoration: none;
                // color: white;
                color: #9e9e9e;
            }
            .link.active {
                text-decoration: none;
                // color: white;
                color: #c62828;
            }
            .book {
                padding: 7px 15px;
                background-color: #c62828;
                color: white;
            }
        }
        .navbar-sidebar.open {
            top: 70px;
            transition: 500ms;
        }
    }
}


@media only screen and (min-width: 961px) and (max-width: 1300px){ //Tablets and Desktops

    .navBar {
        .navbar-hamburger {
            display: none;
        }

    }
}

@media only screen and (min-width: 1301px) and (max-width: 2000px){ //Extra large Desktops

    .navBar {
        .navbar-hamburger {
            display: none;
            border: 1px solid red;
            width: 0px;
            
            .hamOne {
                display: none;
            }
            
            .hamTwo {
                display: none;
            }
            
            .hamThree {
                display: none;
            }
        }

    }
}