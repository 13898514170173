.contact-container {
    display: flex;
    flex-direction: column;
    // background-color: #263238;
    // background-color: #c62828;    
    // background: linear-gradient(
    //     #c62828,
    //     #b71c1c
    //     #e57373
    // );

    .contact-banner {
        // border: 1px solid pink;
        width: 100%;
        height: 160px;
        position: relative;
        background-image: url("../../assets/images/contact1.jpg");
        background-position: bottom;
        background-size: cover;
        background-position-y: -300px;

        .contact-darkOverlay {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: black;
            opacity: 0.4;
            z-index: 1;
        }

        .contact-header {
            max-width: 1100px;
            // border: 1px solid red;
            margin: auto auto;
            height: 100%;
            display: flex;
            align-items: center;
            font-size: 40px;
            color: white;

            p{
                z-index: 7;
            }
        }
    }

    .contact-body {
        height: 160px;
        // border: 1px solid red;
        background-color: #e6e6e6;
        display: flex;
        align-items: center;
        justify-content: center;

        .contact-wrapper {
            width: 1100px;
            border: 1px solid blue;
        }
    }

}