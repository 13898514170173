.gallery-container {

    position: relative;

    .gallery-banner {
        // border: 1px solid pink;
        width: 100%;
        height: 160px;
        position: relative;
        background-image: url("../../assets/images/steth.jpg");
        background-position: bottom;
        background-size: cover;
        background-position-y: -400px;

        .gallery-darkOverlay {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: black;
            opacity: 0.4;
            z-index: 1;
        }

        .gallery-header {
            max-width: 1100px;
            // border: 1px solid red;
            margin: auto auto;
            height: 100%;
            display: flex;
            align-items: center;
            font-size: 50px;
            color: white;

            p{
                z-index: 7;
            }
        }
    }

    .gallery-videos {
            max-width: 1100px;
            margin: auto auto;
            // border: 1px solid black;

            .gallery-videos-header {
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 80px;
                border-left: 4px solid #c62828;
                margin: 10px;
            }

            .gallery-videos-wrapper {
                display: flex;
                flex-direction: row;
                // border: 1px solid red;

                .gallery-video-item {
                    display: flex;
                    flex-direction: column;
                    margin: 10px;

                    .gallery-video-cover {
                        width: 200px;
                        height: 150px;
                        // border: 1px solid black;
                        // background-image: url("../../assets/images/interview1.PNG");
                        // background-size: cover;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        overflow: hidden;

                        .video-cover {
                            background-size: cover;
                            width: 270px;
                            height: 150px;
                        }

                        .gallery-video-play {
                            padding: 15px;
                            height: 15px;
                            width: 15px;
                            border-radius: 50%;
                            background-color: #c62828;
                            opacity: 0.7;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            // margin: auto auto;
                            position: absolute;
                            // z-index: 1;
                            cursor: pointer;

                            .play-button {
                                width: 15px;
                                // position: absolute;
                                // z-index: 4;
                            }
                        }

                    }

                    .gallery-video-description {
                        display: flex;
                        flex-direction: column;
                        // font-size: 12px;

                        .gallery-video-text1 {
                            font-size: 12px;
                            padding: 3px 8px;
                            width: max-content;
                            color: white;
                            background-color: #c62828;
                        }

                        .gallery-video-text2 {
                            font-size: 12px;
                            width: 200px;
                        }
                    }

                }
            }
    }

    .video-player-wrapper {
        position: absolute;
        // top: 10px;
        width: 100%;
        height: 100vh;
        top: -10px;
        background-color: black;
        z-index: 13;
        // margin: 0px auto 0px auto;
        // border: 1px solid red;
        display: flex;
        align-items: center;
        justify-content: center;

        .video-player {
            position: relative;
            margin: 0px auto 0px auto;
    
            .close-wrapper {
                width: 20px;
                height: 20px;
                background-color: #c62828;
                padding: 15px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 10px;
                right: 10px;
                position: absolute;
                z-index: 1;
    
                .close-btn {
                    width: 15px;
                }
            }

            video {
                width: 95%;
                // margin: 0px auto 0px auto;
            }
        }

        .video-player.inactive {
            display: none;
        }

    }

    .video-player-wrapper.inactive {
        display: none;
    }

    .gallery-pictures {
        max-width: 1100px;
        margin: auto auto;

        .gallery-pictures-header {
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            border-left: 4px solid #c62828;
            margin: 10px;
        }

        .gallery-pictures-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .gallery-pictures-item {
                width: 200px;
                height: max-content;
                margin: 10px;

                .pic-cover {
                    width: 200px;
                    height: 200px;
                    background-size: cover;
                }
            }
        }
}


}

@media only screen and (min-width: 320px) and (max-width: 400px){ //Mobile

    .gallery-container {
            // display: flex;
            // border: 1px solid black;
        .gallery-banner {
            // border: 1px solid pink;
            width: 100%;
            height: 100px;
            background-position: bottom;
            background-size: cover;
            background-position-y: -300px;
            .gallery-header {
                font-size: 40px;
                margin-left: 40px;
            }
        }

        .gallery-videos {
            
            .gallery-videos-wrapper {
                flex-wrap: wrap;
                justify-content: space-around;

                .gallery-video-item {
                    // border: 1px solid red;
                    width: 130px;

                    .gallery-video-cover {
                        width: 130px;
                        height: 120px;
                        // border: 1px solid black;
                        // background-image: url("../../assets/images/interview1.PNG");
                        // background-size: cover;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        overflow: hidden;

                        .video-cover {
                            background-size: cover;
                            width: 220px;
                            height: 100px;
                        }

                        .gallery-video-play {
                            padding: 15px;
                            height: 10px;
                            width: 10px;
                            border-radius: 50%;
                            background-color: #c62828;
                            opacity: 0.7;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            // margin: auto auto;
                            position: absolute;
                            // z-index: 1;
                            cursor: pointer;

                            .play-button {
                                width: 13px;
                                // position: absolute;
                                // z-index: 4;
                            }
                        }

                    }

                    .gallery-video-description {
                        display: flex;
                        flex-direction: column;
                        height: max-content;
                        // font-size: 12px;

                        .gallery-video-text1 {
                            font-size: 11px;
                            padding: 3px 8px;
                            width: max-content;
                            color: white;
                            background-color: #c62828;
                        }

                        .gallery-video-text2 {
                            font-size: 10px;
                            width: 100%;
                        }
                    }

                }                
            }
        }
    }
}