.blog-container {

    .blog-banner {
        // border: 1px solid pink;
        width: 100%;
        height: 160px;
        position: relative;
        background-image: url("../../assets/images/bg6.jpg");
        background-position: bottom;
        background-size: cover;
        background-position-y: -400px;

        .blog-darkOverlay {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: black;
            opacity: 0.4;
            z-index: 1;
        }

        .blog-header {
            max-width: 1100px;
            // border: 1px solid red;
            margin: auto auto;
            height: 100%;
            display: flex;
            align-items: center;
            font-size: 50px;
            color: white;

            p{
                z-index: 7;
            }
        }
    }

    .blog-nothing {

        .blog-wrapper {

            display: flex;
            flex-direction: column;
            // border: 1px solid black;
            border-radius: 50%;
            width: 500px;
            height: 500px;
            padding: 20px;
            margin: 30px auto 100px auto;
            background-color: #fafafa;

            .blog-image {
                // background-image: url('../../assets/images/nopost.png');
                height: 350px;
                width: 350px;
                background-size: cover;
                display: flex;
                align-items: center;
                justify-content: center;
                // border: 1px solid red;
                margin: 0px auto 0px auto;

                img {
                    width: 300px;
                    margin: 0px auto 0px auto;
                    // border: 1px solid red;
                }
            }

            .blog-text {
                text-align: center;
                p:nth-child(1) {
                    font-size: 25px;
                    margin-bottom: 30px;
                }
                p:nth-child(2) {
                    font-size: 15px;
                    opacity: 0.4;
                    width: 250px;
                    margin: 0px auto 0px auto;
                }
            }
            
        }
    }

    // .blog-journey {

    //     margin: 50px;

    //     .blog-journey-wrapper {
    //         max-width: 1300px;
    //         // border: 1px solid red;
    //         height: 300px;
    //         margin: 0px auto;
    //         display: flex;
    //         gap: 20px;

    //         .blog-journey-left {
    //             width: 50%;
    //             height: 100%;
    //             background-image: url("../../assets/images/headoffice.jpg");
    //             background-size: cover;
    //         }
    //         .blog-journey-right {
    //             width: 50%;

    //             .blog-journey-header {
    //                 font-size: 30px;

    //                 hr {
    //                     margin: 10px 0px 10px 0px;
    //                     width: 40px;
    //                     border: 1px solid #c62828;
    //                 }
    //             }

    //             p {
    //                 font-weight: 300;
    //             }
    //         }
    //     }
    // }
}

@media only screen and (min-width: 320px) and (max-width: 400px){ //Mobile

    .blog-container {
            // display: flex;
            // border: 1px solid black;
        .blog-banner {
            // border: 1px solid pink;
            width: 100%;
            height: 100px;
            background-position: bottom;
            background-size: cover;
            // background-position-y: -400px;
            .blog-header {
                font-size: 40px;
                margin-left: 40px;
            }
        }

        .blog-nothing {

            .blog-wrapper {
    
                display: flex;
                flex-direction: column;
                // border: 1px solid black;
                border-radius: 50%;
                width: 300px;
                height: 300px;
                padding: 20px;
                margin: 30px auto 100px auto;
                background-color: #fafafa;
    
                .blog-image {
                    // background-image: url('../../assets/images/nopost.png');
                    height: 300px;
                    width: 300px;
                    background-size: cover;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // border: 1px solid red;
                    margin: 0px auto 0px auto;
    
                    img {
                        width: 170px;
                        margin: 0px auto 0px auto;
                        // border: 1px solid red;
                    }
                }
    
                .blog-text {
                    text-align: center;
                    p:nth-child(1) {
                        font-size: 25px;
                        margin-top: 10px;
                    }
                    p:nth-child(2) {
                        font-size: 13px;
                        opacity: 0.4;
                        width: 250px;
                        margin: 0px auto 20px auto;
                    }
                }
                
            }
        }
    }
}


@media only screen and (min-width: 401px) and (max-width: 450px){ //Mobile

    .blog-container {
            // display: flex;
            // border: 1px solid black;
        .blog-banner {
            // border: 1px solid pink;
            width: 100%;
            height: 100px;
            background-position: bottom;
            background-size: cover;
            // background-position-y: -400px;
            .blog-header {
                font-size: 40px;
                margin-left: 40px;
            }
        }

        .blog-nothing {

            .blog-wrapper {
    
                display: flex;
                flex-direction: column;
                // border: 1px solid black;
                border-radius: 50%;
                width: 300px;
                height: 300px;
                padding: 20px;
                margin: 30px auto 100px auto;
                background-color: #fafafa;
    
                .blog-image {
                    // background-image: url('../../assets/images/nopost.png');
                    height: 300px;
                    width: 300px;
                    background-size: cover;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // border: 1px solid red;
                    margin: 0px auto 0px auto;
    
                    img {
                        width: 170px;
                        margin: 0px auto 0px auto;
                        // border: 1px solid red;
                    }
                }
    
                .blog-text {
                    text-align: center;
                    p:nth-child(1) {
                        font-size: 25px;
                        margin-top: 10px;
                    }
                    p:nth-child(2) {
                        font-size: 13px;
                        opacity: 0.4;
                        width: 250px;
                        margin: 0px auto 30px auto;
                    }
                }
                
            }
        }
    }
}