 
 .heroCarousel-container {
    width: 100%;
    // height: 400px;
    height: 80vh;
    //background-color: aqua;
    //background-image: url("../../assets/images/bg9.jpg");
    background-size: cover;
    position: relative;
    overflow: hidden;
    // margin-top: -60px;
    z-index: -2;

    img {
        width: 100%;
        // height: 80vh;
        background-size: cover ;
        margin-top: -150px;
    }

    .overlay {
        background-color: black;
        // background-color: #c62828;
        width: 100%;
        height: 100%;
        opacity: 0.2;
        position: absolute;
    }

    .dots {
        display: flex;
        width: 100px;
        height: 20px;
        // border: 1px solid;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 10px;
        margin-left: 50%;
        margin-right: 50%;
        
        .dot {
            border-radius: 50%;
            border: 3px solid #EAB543;
            width: 15px;
            height: 15px;
        }

        .dot.active {
            background: #2196f3;
        }
    }
 }

@media only screen and (min-width: 320px) and (max-width: 400px){ //Mobile

        .heroCarousel-container {
                width: 100%;
                // height: 400px;
                height: 200px;
                // margin-top: -60px;
                img {
                    width: 100%;
                    max-height: 100%;
                    background-size: contain ;
                    margin-top: 0px;
                }
            .dots {
                display: none;

                .dot { 
                    display: none;
                }

                .dot.active {
                    background: #2196f3;
                }
            }
        }    

}


@media only screen and (min-width: 401px) and (max-width: 450px){ //Mobile
    .heroCarousel-container {
            width: 100%;
            // height: 400px;
            height: auto;
        .dots {
            display: none;

            .dot { 
                display: none;
            }

            .dot.active {
                background: #2196f3;
            }
        }
    } 
}

@media only screen and (min-width: 801px) and (max-width: 960px){ //Tablet
    .heroCarousel-container {
            width: 100%;
            // height: 400px;
            height: auto;
        .dots {
            display: none;

            .dot { 
                display: none;
            }

            .dot.active {
                background: #2196f3;
            }
        }
    } 
}