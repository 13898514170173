.about-container {

    .about-banner {
        // border: 1px solid pink;
        width: 100%;
        height: 160px;
        position: relative;
        background-image: url("../../assets/images/hosp.jpg");
        background-position: bottom;
        background-size: cover;
        background-position-y: -400px;

        .about-darkOverlay {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: black;
            opacity: 0.4;
            z-index: 1;
        }

        .about-header {
            max-width: 1100px;
            // border: 1px solid red;
            margin: auto auto;
            height: 100%;
            display: flex;
            align-items: center;
            font-size: 50px;
            color: white;

            p{
                z-index: 7;
            }
        }
    }

    .about-journey {

        margin: 50px;

        .about-journey-wrapper {
            max-width: 1100px;
            // border: 1px solid red;
            height: 300px;
            margin: 0px auto;
            display: flex;
            gap: 20px;

            .about-journey-header.mobile {
                display: none;
            }

            .about-journey-left {
                width: 50%;
                height: 100%;
                background-image: url("../../assets/images/headoffice.jpg");
                background-size: cover;
            }
            .about-journey-right {
                width: 50%;

                .about-journey-header {
                    font-size: 30px;

                    hr {
                        margin: 10px 0px 10px 0px;
                        width: 40px;
                        border: 1px solid #c62828;
                    }
                }

                p {
                    font-weight: 300;
                }
            }
        }
    }
}


@media only screen and (min-width: 320px) and (max-width: 400px){ //Mobile

    .about-container {
            // display: flex;
            // border: 1px solid black;
        .about-banner {
            // border: 1px solid pink;
            width: 100%;
            height: 100px;
            background-position: bottom;
            background-size: cover;
            // background-position-y: -400px;
            .about-header {
                font-size: 40px;
                margin-left: 40px;
            }
        }

        .about-journey {
            width: 100%;
            margin: 0px auto 0px auto;
            // border: 1px solid black;

            .about-journey-wrapper {
                flex-direction: column;
                height: max-content;
                // border: 1px solid red;

                .about-journey-header.mobile {
                    font-size: 20px;
                    // border: 1px solid black;
                    display: block;
                    margin-top: 20px;
                    padding: 0px 10px 0px 10px;


                    hr {
                        margin: 10px 0px 10px 0px;
                        width: 40px;
                        border: 1px solid #c62828;
                    }
                }

                .about-journey-left {
                    height: 200px;
                    width: 100%;
                    background-size: cover;

                }

                .about-journey-right {
                    height: max-content;
                    width: 90%;
                    margin: 0px auto 0px auto;
                    font-size: 16px;

                    .about-journey-header {
                        display: none;
                    }
                }
            }
        }
    }
}


@media only screen and (min-width: 401px) and (max-width: 450px){ //Mobile
    
    .about-container {
            // display: flex;
            // border: 1px solid black;
        .about-banner {
            // border: 1px solid pink;
            width: 100%;
            height: 100px;
            background-position: bottom;
            background-size: cover;
            // background-position-y: -400px;
            .about-header {
                font-size: 40px;
                margin-left: 40px;
            }
        }

        .about-journey {
            width: 100%;
            margin: 0px auto 0px auto;
            // border: 1px solid black;

            .about-journey-wrapper {
                flex-direction: column;
                height: max-content;
                // border: 1px solid red;

                .about-journey-header.mobile {
                    font-size: 20px;
                    // border: 1px solid black;
                    display: block;
                    margin-top: 20px;
                    padding: 0px 10px 0px 10px;


                    hr {
                        margin: 10px 0px 10px 0px;
                        width: 40px;
                        border: 1px solid #c62828;
                    }
                }

                .about-journey-left {
                    height: 200px;
                    width: 100%;
                    background-size: cover;

                }

                .about-journey-right {
                    height: max-content;
                    width: 90%;
                    margin: 0px auto 0px auto;
                    font-size: 16px;

                    .about-journey-header {
                        display: none;
                    }
                }
            }
        }
    }

}